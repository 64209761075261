<template>
    <div>
        <div class="survey">
            <div>
                <div style="background:#fff;padding-bottom:10px">
                    <van-form @submit="save" :colon='true'>
                        <div>
                            <div class="signature-box">
                                <div class="signature-box-title"><span>*</span>签名:</div>
                                <div style="background:rgb(250,250,250)">
                                    <VueSignaturePad 
                                        id="signature"
                                        height="200px" 
                                        :customStyle="{ border:'1px dashed #BFBFBF'}"
                                        :options="SignatureOptions"
                                        ref="signaturePad" />
                                </div>
                                <van-row gutter="20">
                                    <van-col span="24"><div class="signature-btn" @click="undo" type="default">清除</div></van-col>
                                </van-row>
                            </div>
                            
                        </div>
                        <div style="margin: 16px;">
                            <van-button round block type="info" native-type="submit">
                            提交
                            </van-button>
                        </div>
                    </van-form>
                </div>
            </div>
            <loadings :loading='loading' />
        </div>
    </div>
</template>

<script>

    import areaList from '@/common/huidongArea'
    import upload from '@/utils/upload'
    import Loadings from '@/components/loading'
    import dayjs from 'dayjs'
    import resApi from '@/api'
    import { wxPay } from '@/common/wx'
    import stepOne from './stepOne'
    import stepTwo from './stepTwo'
    import agreement from './agreement'
    import { lazyAMapApiLoaderInstance } from 'vue-amap';
    import { Checkbox, CheckboxGroup, Form, Field, RadioGroup, Radio, Picker, Popup, DatetimePicker, Uploader ,Overlay, Step, Steps, Skeleton, Area} from 'vant'

    export default {
        name:"huidongApply",
        components: {
            [Field.name]: Field,
            [Form.name]: Form,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Overlay.name]: Overlay,
            [Step.name]: Step,
            [Steps.name]: Steps,
            [Skeleton.name]: Skeleton,
            [Area.name]: Area,
            loadings:Loadings,
            stepOne,
            stepTwo,
            agreement
        },
        data() {
            return {
                detail: {},
                questions:[],
                showPicker:false,
                showPickers:false,
                showPickert:false,
                showAjaxPicker:false,
                showAjaxSubPicker:false,
                showStudio:false,
                showTeacher:false,
                showMapAddress:false,
                showCourseConsultant:false,
                isAgreement:false,
                survey:{},
                SignatureOptions:{
                    penColor: "#000",
                },
                survey_id:'',
                signaturePad:'',
                survey_msg:{},
                recruit_id:'',
                minDate: new Date(1970, 1, 1),
                maxDate: new Date(9999, 12, 30),
                activeIndex:-1,
                newValue:'',
                isMap:false,
                loading:false,
                isCheck:false,
                active:0,
                apply_id:null,
                applyInfo:{},
                showSkeleton:false,
                areaList: areaList,
                signaturePadBase64:'',
                agreementHtml: ''
            }
        },
        async created () {
            this.apply_id = this.$route.query.apply_id
        },
        methods: {
            onSubmit(value){
                let that = this
                this.$api.custom_huidong_signature({signature:this.signaturePad,apply_id:this.apply_id})
                    .then(res => {
                        this.$toast({ message: '提交成功~', duration: 1500 })
                        this.loading = false
                        this.$router.push({path:'/huidong-apply'})
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$toast({ message: err, duration: 1500 })
                    })
            },
            beforeDelete(file,detail,name){
                this.survey[name].splice(detail.index,1)
                this.$forceUpdate()
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
                return true
            },
            undo() {
                this.$refs.signaturePad.openSignaturePad()
                this.$refs.signaturePad.clearSignature()
            },
            save(value) {
                this.loading = true
                let that = this
                if(this.$refs.signaturePad){
                    const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
                    if(!isEmpty){
                    let file = this.base64toFile(data)
                    if(file.size == 0){
                        this.$toast({ message: '您的签名有问题,请重新签名~', duration: 1500 })
                        this.$refs.signaturePad.clearSignature()
                        this.loading = false
                        return false
                    }
                    this.$refs.signaturePad.lockSignaturePad()
                    upload({ file: file})
                        .then(res => {
                            that.signaturePad = res.fileName
                            that.onSubmit(value)
                        })
                        .catch(err=>{
                            that.loading = false
                            that.$refs.signaturePad.openSignaturePad()
                            that.$toast({ message: '您的签名上传失败~', duration: 1500 })
                        })
                    }else{
                        this.loading = false
                        this.$toast({ message: '请签名~', duration: 1500 })
                    }
                }else{
                    this.onSubmit(value)
                }
            },
            base64toFile (dataurl, filename = 'file') {
                let arr = dataurl.split(',')
                let mime = arr[0].match(/:(.*?);/)[1]
                let suffix = mime.split('/')[1]
                let bstr = atob(arr[1])
                let n = bstr.length
                let u8arr = new Uint8Array(n)
                while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
                })
            },
        },
    }
</script>

<style lang="less">
    .survey{
        padding: 0 10px;
        background: #fff;
        &-name{
            padding:15px 0;
            font-size:16px;
            font-weight: bold;
        }
        .van-popup--bottom{
            z-index: 2200 !important;
        }
        .signature-box{
            background: #fff;
            padding: 0.2rem 0.32rem;
            .signature-box-title{
                margin-bottom: 5px;
                position: relative;
                span{
                    position: absolute;
                    left: -0.16rem;
                    color: #ee0a24;
                    font-size: 14px;
                }
            }
            .signature-btn{
                color: #323233;
                background-color: #fff;
                border: 1px solid #ebedf0;
                text-align: center;
                padding: 10px 0;
                border-radius: 2px;
                margin-top: 10px;
            }
        }
        #signature {
            width: auto !important;
            border: double 3px transparent;
            border-radius: 5px;
            // background-image: linear-gradient(white, white),
            //     radial-gradient(circle at top left, #4bc5e8, #9f6274);
            background-origin: border-box;
            background-clip: content-box, border-box;
        }
        .van-cell{
            display: block;
        }
        .van-cell:not(:last-child)::after{
            border-bottom:none
        }
        .van-field__label{
            width: 100%;
        }
        .van-button--info{
            background-color: #00DE93;
            border:1px solid #00DE93;
        }
        .report-btn{
            width: 100%;
            height: 50px;
            background-color: #00DE93;
            position: fixed;
            bottom: 0;
            left: 0;
            text-align: center;
            line-height: 50px;
            color: #fff;
        }
        .survey-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-radio-box{
            .van-radio-group--horizontal,.van-checkbox-group--horizontal{
                display: block;
                width: 100%;
                .survey-van-radio-boeder{
                    border: 1px solid #d9d9d9;
                    padding: 5px;
                    border-radius: 2px;
                    margin-bottom: 5px;
                }
                .survey-van-radio-boederCheck{
                    border: 1px solid #00DE93;
                    padding: 5px;
                    border-radius: 2px;
                    margin-bottom: 5px;
                }
            }
        }
        .location{
            background: #fff;
            padding: 0.2rem 0.32rem;
            &-title{
                margin-bottom: 5px;
                position: relative;
                span{
                    position: absolute;
                    left: -0.16rem;
                    color: #ee0a24;
                    font-size: 14px;
                }
            }
            &-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #d9d9d9;
                height: 100px;
            }
            &-map{
                border: 1px solid #d9d9d9;
                position: relative;
            }
            &-inp{
                border-top: 1px solid #d9d9d9;
            }
            &-address{
                padding: 5px;
                border-left: 1px solid #d9d9d9;
                border-right: 1px solid #d9d9d9;
                border-bottom: 1px solid #d9d9d9;
            }
            &-icon{
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 100;
            }
            .map-wrap {
                height: 150px;
            }
        }
        .agreement{
            padding: 0 10px;
            padding-top: 20px;
            padding-bottom: 50px;
        }
        .ql-editor{
            line-height: 1.7;
        }
    }
</style>